import { useAppDispatch, useAppSelector } from "Hooks/useRedux";
import { login } from "Redux/authSlice";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

type ProtectedRouteProps = {
  redirectTo?: string;
  children?: React.ReactElement | null;
};

export const ProtectedRoutes: React.FC<ProtectedRouteProps> = ({ redirectTo = "/", children}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sessionStorage.getItem('token')){
      dispatch(login({ token: sessionStorage.getItem('token') }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated || sessionStorage.getItem('token'));
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  return children || <Outlet />;
};
