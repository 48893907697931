import styled from "styled-components";
import 'Styles/_mixins.scss'

export const LanguageBox = styled.div`
height: 0;
width: 0;
position:absolute;
`
export const LanguageDropDown = styled.ul`
position: relative;
font-family: "SFProDisplay-Regular";
font-size: 1rem;
top: 2rem;
right: 8.75rem;
background: $baipro-white;	
box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.3);
width: 8.7rem;
display: flex;
flex-direction: column;
align-items: center;
z-index: 99999;
background: white;
@media screen and (max-width: 600px) {
    font-size: 1.5rem; 
  }
`
export const LanguageLine = styled.div`

    height: 0.1rem;
    width: 2rem;
    background: lightblue;
`

export const LanguageElement = styled.li`
list-style: none;
width: 100%;
padding: 0.8rem;
button {
    background: transparent;
    border: 0;
    width: 5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
}
&:hover{
    font-family: "SFProDisplay-Regular";
    scale: 102%;
    transform: scale 0.5s ease;
    color: purple;
}
`