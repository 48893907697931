
import Logo  from 'Assets/images/Logo_con_slogan.png';

import './Loading.scss'

const Loading = () => {
  return (
    <div className='loading-container'>
        <img className='loading-image' src={Logo} alt="" />
    </div>
  )
}

export default Loading