import { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import SearchSelect from '../Search/Search';

import SelectLanguages from '../Language/Language';
import { ReactComponent as Lenguage } from 'Assets/icons/icon-language.svg';
import { ReactComponent as Search } from 'Assets/icons/icon-search.svg';
import Bars from 'Assets/images/icon-bars-nav.png';
import Cross from 'Assets/images/icon-cross-nav.png';
import Logo from 'Assets/images/Logo-sin-slogan.png';
import Baipro from 'Assets/images/Logo_baipro.png';

import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import { ClickAwayListener } from '@mui/base';

import { useTranslation } from 'react-i18next';

import './Navbar.scss';


const windowHeigth = window.innerHeight;
type NavbarProps = {
  onChange: Function;
  isMobile: Boolean;
};

const Navbar: React.FC<NavbarProps> = ({ onChange, isMobile }) => {
  const { t } = useTranslation();
  const goTo = useNavigate();
  const [openLanguage, setOpenLanguage] = useState(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const location = window.location.pathname;

  const [openSearch, setOpenSearch] = useState(false);



  const isAuthenticated = sessionStorage.getItem('token') !== null ?? false;




  const handleListItemClick = () => {
    setAnchor(null);
  };

  const handleClosePopup = (event: MouseEvent) => {
    if (ulRef.current && !ulRef.current.contains(event.target as Node)) {
      setAnchor(null);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;


  const handleOpenLanguage = () => {
    setOpenLanguage(!openLanguage);
  };
  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
    setOpenLanguage(false);
  };

  const handleClickAway = () => {
    setOpenSearch(false);
  };
  const handleClickAwayLenguage = () => {
    setOpenLanguage(false);
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClosePopup);
    return () => {
      document.removeEventListener('mousedown', handleClosePopup);
    };
  }, []);


  function scrollToElement(
    elementId: string,
    offsetFactor: number,
    windowHeigth: number
  ): void {
    const offset = windowHeigth / offsetFactor;
    setTimeout(() => {
      let element = document.getElementById(elementId);

      if (!element) {
        goTo('/');
        setTimeout(() => {
          element = document.getElementById(elementId);
          if (!element) {
            return;
          }
          window.scrollTo({
            top: element.offsetTop - offset,
            behavior: 'smooth',
          });
          return;
        }, 10);
      } else {
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: 'smooth',
        });
      }
    }, 50);
  }
  return (
    <>
      {isMobile ? (
        <>
          <nav className='NavMobile' >
            <div className='NavMobile-left'>
              <NavLink to="/" >
                <img src={Logo} alt='Logo de baipro' className='NavMobile-left__Logo' />
              </NavLink>
              <NavLink to="/" >
                <img src={Baipro} alt='Logo del texto' className='NavMobile-left__logoText' />
              </NavLink>
            </div>

            <div className='NavMobile-right'>
              <ClickAwayListener onClickAway={handleClickAwayLenguage}>
                <button onClick={handleOpenLanguage} aria-label='lenguage'
                  className='NavMobile-right__btnLenguage'
                >
                  <Lenguage />
                  {openLanguage ? (
                    <SelectLanguages />
                  ) : null}
                </button>
              </ClickAwayListener>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
              >
                <button aria-describedby={id} type="button" onClick={handleClick} className='navBaipro_hamburger' aria-label='navbar' >
                  {id ? <img src={Cross} alt="imagen cross" /> : <img src={Bars} alt="img bars" />}
                </button>
              </ClickAwayListener>
            </div>

            <Popup id={id} anchor={anchor} open={open} >
              <ul className='NavMobile-list' ref={ulRef}>
                <li onClick={() => {
                  scrollToElement('services', 10, windowHeigth)
                  handleListItemClick();
                }} >
                  <NavLink to="/">{t('header.services')}</NavLink>
                </li>
                <li onClick={() => {
                  scrollToElement('projects', 7, windowHeigth)
                  handleListItemClick();
                }} >
                  <NavLink to="/">{t('header.projects')} </NavLink>
                </li>
                <li onClick={() => handleListItemClick()}  >
                  <NavLink to="/pillars">{t('header.pillars')} </NavLink>
                </li>

                <li onClick={() => handleListItemClick()}>
                  <NavLink to="/careers">{t('header.careers')} </NavLink>
                </li>
                <li onClick={() => {
                  scrollToElement('contact', 10, windowHeigth)
                  handleListItemClick()
                }} >
                  <NavLink to="/">{t('header.contact')} </NavLink>
                </li>
                <li onClick={() => {
                  scrollToElement('footprint', 7, windowHeigth)
                  handleListItemClick()
                }} >
                  <NavLink to="/">{t('header.footprint')} </NavLink>
                </li>
                {isAuthenticated ? (
                  <li onClick={() => handleListItemClick()}>
                    <NavLink to="/adminHome">
                      <button className='btnLoginMobile'>Home</button>
                    </NavLink>
                  </li>
                ) : (
                  <li onClick={() => { handleListItemClick() }}>
                    <NavLink to="/login">
                      <button className='btnLoginMobile'>{t("header.login")}</button>
                    </NavLink>
                  </li>
                )}
              </ul>
            </Popup>
            {open && (
              <div className="menu-overlay" onClick={handleClick}></div>
            )}
          </nav>
        </>
      ) : (
        <>
          <nav className='navBaipro'>
            <NavLink to="/" className='navBaipro-list_logo'>
              <img src={Logo} alt='Logo de baipro' />
            </NavLink>
            <ul className='navBaipro-list'>
              <li onClick={() => scrollToElement('services', 10, windowHeigth)}>
                <NavLink to="/">{t('header.services')}</NavLink>
              </li>
              <li onClick={() => scrollToElement('projects', 10, windowHeigth)}>
                <NavLink to="/">{t('header.projects')} </NavLink>
              </li>
              <li>
                <NavLink to="/pillars">{t('header.pillars')} </NavLink>
              </li>

              <li>
                <NavLink to="/careers">{t('header.careers')} </NavLink>
              </li>
              <li onClick={() => scrollToElement('contact', 100, windowHeigth)}>
                <NavLink to="/">{t('header.contact')} </NavLink>
              </li>
              <li onClick={() => scrollToElement('footprint', 90, windowHeigth)}>
                <NavLink to="/">{t('header.footprint')} </NavLink>
              </li>
              {isAuthenticated ? (
                <li>
                  <NavLink to="/adminHome">
                    <button className="navBaipro-list_btnLogin">Home</button>
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink to="/login" className={location === '/' ? window.scrollY>50 ? 'visibilityYes': 'visibilityNone' : 'visibilityYes'}>
                    <button className="navBaipro-list_btnLogin">{t("header.login")}</button>
                  </NavLink>
                </li>
              )}
            </ul>
            <div className={location === '/' ? window.scrollY>50 ? 'visibilityYes navBaipro-icon': 'visibilityNone' : 'visibilityYes navBaipro-icon'} >
              <ClickAwayListener onClickAway={handleClickAwayLenguage}>
                <div className='navBaipro-icon_languages'>
                  <button onClick={handleOpenLanguage} aria-label='lenguage'>
                    <Lenguage />
                    {/* <img src={LanguageNeo} alt="" /> */}
                    {openLanguage ? (
                      <SelectLanguages />
                    ) : null}
                  </button>
                </div>
              </ClickAwayListener>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
              >
                <div className='navBaipro-icon_search'>
                  <button
                    onClick={handleOpenSearch}
                  >
                    <Search />
                    {openSearch ? <SearchSelect /> : null}
                  </button>
                </div>
              </ClickAwayListener>
            </div>
          </nav>
        </>
      )}
    </>
  )
}


export default Navbar;