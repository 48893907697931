import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import proposalsReducer from "./proposalsSlice";

const store = configureStore({
    reducer: {
      auth: authReducer,
      proposals: proposalsReducer, 
    },
  });  

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;