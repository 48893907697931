import { createSlice } from "@reduxjs/toolkit";

export type Tproposal = {
  _id: string;
  title: string;
  location: string;
  workModel: string;
  description: string;
  workTasks: string[];
  workChallenges: string[];
  workConditions: string[];
  proposals: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

const initialState = {
  proposalsList: Array<Tproposal>(),
};

const proposalsSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {
    getProposals(state, action) {
      const { data } = action.payload;
      state.proposalsList = data;
    },
    deleteProposal(state, action) {
      const { id } = action.payload;
      const index = state.proposalsList.findIndex(
        (proposal) => proposal._id === id
      );
      state.proposalsList.splice(index, 1);
    }
  },
});

export const { getProposals, deleteProposal } = proposalsSlice.actions;
export default proposalsSlice.reducer;