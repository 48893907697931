import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Image
import  logo  from 'Assets/images/Logo-sin-slogan.png';

// Component
import Socials from 'Components/Socials';

// Style
import './Footer.scss';

const Footer = () => {

  const { t } = useTranslation();
  const [isDesktop, setMobile] = useState(window.innerWidth > 900);

  const updateMedia = () => {
    setMobile(window.innerWidth > 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  

  return (
    <>
    {isDesktop &&
    <div data-testid="footer" className="footer">
      <NavLink to="/" className="footer--a"><img src={logo} alt="" className="footer--img" /></NavLink>
      <span>{t('footer.created')}</span>
      <div className="footer_terms">
          <NavLink to="/"><span>{t('footer.cancelNewsletter')}</span></NavLink>
            <NavLink to="/privacy"><span>{t('footer.terms')}</span></NavLink>
            <div className="footer_socials">
                <div className="footer_socials--follow">
                  <Socials height={"1.2rem"} justifyContent={"flex-end"} />
                </div> 
            </div>
      </div>
    </div>
    }
    {!isDesktop &&
       <div data-testid="footer" className="footer">
       <span className="mobile-footer">
       <NavLink to="/" className="footer--a"><img src={logo} alt="" className="footer--img" /></NavLink>
       <div className="footer_terms">
           <NavLink to="/"><span>{t('footer.cancelNewsletter')}</span></NavLink>
             <NavLink to="/privacy"><span>{t('footer.terms')}</span></NavLink>
       </div>
       <span>{t('footer.created')}</span>
       </span>
       <div className="footer_socials">
             <div className="footer_socials--follow">
               <Socials height={"2.2rem"} justifyContent={"flex-end"} />
             </div>           
       </div>
     </div>
    }
    </>
  )
};

export default Footer;
