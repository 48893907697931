import React from "react";

// Icons
import { ReactComponent as Facebook } from 'Assets/icons/social-facebook.svg';
import { ReactComponent as Instagram } from 'Assets/icons/social-instagram.svg';
import { ReactComponent as Linkedin } from 'Assets/icons/social-linkedin.svg';


// Style
import './Socials.scss'

const Socials=(prop : any) => {
    const setHeigth = {
        height: prop.height,
        justifyContent: prop.justifyContent
    };

    return (
        <div className="socials" style={setHeigth}>
        <a href="https://www.instagram.com/baipro.ingenieria/" target="_blank" rel="noreferrer">
            <Instagram className="socials_icons" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100064712179196" target="_blank" rel="noreferrer">
            <Facebook className="socials_icons" />
        </a>
        <a href="https://www.linkedin.com/company/baiproingenieria" target="_blank" rel="noreferrer">
            <Linkedin className="socials_icons" />
        </a>
        
    </div>
    )
}

export default Socials;