import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Header from 'Features/Header/Header';
import Footer from 'Features/Footer/Footer';
import { SearchProvider } from 'Context/SearchContext';
import ScrollToTop from 'Utilities/ScrollToTop/scrollToTop';
import Loading from 'Components/Loading';
import { ProtectedRoutes } from 'Services/ProtectedRoutes/ProtectedRoutes';

//Styles
import './App.scss';
import ReactGA from "react-ga4";

ReactGA.initialize('G-VE036M7GYD');

const JobForm = lazy(() => import ('Features/JobForm/JobForm'));
const Login = lazy(() => import ('Features/Login/Login'));
const Landing = lazy(() => import ('Features/Landing/Landing'));
const ContainerCareers = lazy(() => import ('Features/CareersPage/ContainerCareersPage'));
const Pillars = lazy(() => import ('Features/PillarsPage/Pillars'));
const Culture = lazy(() => import ('Features/Landing/Culture/Culture'));
const Legal = lazy(() => import ('Features/Legal/Legal'));
const AdminHome = lazy(() => import ('Features/Admin/AdminHome/AdminHome'));

function App() {
 // Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/", title: "Baipro Landing" });
const { pathname } = useLocation();

useEffect(() => {
  window.scrollTo(0, 0);
}, [pathname]);

  return (
    <SearchProvider>
    <div className="App">
      <Header/>
      <Suspense fallback={<Loading/>} >
          <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="*" element={<Landing />} /> {/* route when page not found */}
              <Route path="/careers" element={<ContainerCareers />} />
              <Route path="/pillars" element={<Pillars />} />
              <Route path='/culture' element={<Culture />} />
              <Route path='/privacy' element={<Legal />} />
              <Route path='/login' element={<Login />} />
              <Route path='/jobs/:id' element={<JobForm/>} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/adminHome/*" element={<AdminHome />} />
              </Route>
          </Routes>
      </Suspense>
      <ScrollToTop />
      <Footer />
    </div>
    </SearchProvider>

  );
}
export default App;
