import React, { createContext, useContext, useState } from 'react'

export interface SearchResult {
    content: string;
    elementRef: Element;
}

interface SearchContextType {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    searchResults: SearchResult[];
    performSearch: (searchTerm: string) => void;
    navigateToResult: (result: SearchResult) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export function useSearch() {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch debe ser usado dentro de un SearchProvider');
    }
    return context;
}

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const navigateToResult = (result: SearchResult) => {
        const targetElement = result.elementRef;
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setSearchTerm('');
            setSearchResults([]);
        }
    };

    const performSearch = (newSearchTerm: string) => {
        if (newSearchTerm === "") {
            setSearchResults([]);
            return;
        }
        const newResults = searchResult(newSearchTerm);
        setSearchResults(newResults);
    };


    function normalize(text: string): string {
        return text.toLowerCase().trim();
    };

    function searchResult(searchTerm: string): SearchResult[] {
        const matchingElements = Array.from(document.querySelectorAll('*:not(script):not(style)'))
            .filter(element => {
                const ignoredTags = ["DIV", "A", "HEADER", "NAV", "UL", "LI","BUTTON"];
                const ignoredClasses = ["header_search--button", "MuiSelect-root", "projectCard_box--category"];

                return (
                    !ignoredTags.includes(element.tagName) &&
                    !ignoredClasses.some(className => element.classList.contains(className))
                );
            });

        const results: SearchResult[] = matchingElements
            .filter(element => {
                const elementText = normalize(element.textContent || "");
                return !elementText.includes(".") && elementText.includes(searchTerm);
            })
            .map(element => ({
                content: normalize(element.textContent || "").trim(),
                elementRef: element
            }));
        return results;
    };


    return (
        <SearchContext.Provider value={{ searchTerm, searchResults, performSearch, navigateToResult, setSearchTerm }}>{children}</SearchContext.Provider>
    )
}
export default SearchContext;