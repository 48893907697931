import React, { useEffect, useState } from "react";

// Components
import Navbar from "Features/Header/Navbar/Navbar";
// Styles
import "./Header.scss";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 599);
  const [scroll, setScroll] = useState(0);
  const location = useLocation();

  const handleMobileChange = (isMobile: boolean) => {
    setMobile(isMobile);
  };

  const updateMedia = () => {
    setMobile(window.innerWidth <= 599);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  useEffect(() => {
    if (location.pathname === "/") {
      window.onscroll = function () {
        setScroll(window.scrollY);
        if (scroll > 50) {
          document.getElementById("header_container")?.classList.remove("header_container--trasparent");

          document.getElementById("header_container")?.classList.add("header_container--white");
        } else {
          document.getElementById("header_container")?.classList.remove("header_container--white");
        }
      };
    }
  }, [scroll, location.pathname]);

  useEffect(() => {
    if (location.pathname !== "/") {
      document
        .getElementById("header_container")
        ?.classList.remove("header_container--trasparent");
      document
        .getElementById("header_container")
        ?.classList.add("header_container--white");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div data-testid="header" className="divHead">
      <header
        className="header_container header_container--trasparent "
        id="header_container"
      >
        <Navbar onChange={handleMobileChange} isMobile={isMobile} />
      </header>
    </div>
  );
};

export default Header;
