import React from 'react'
import {ReactComponent as IconArrow} from 'Assets/icons/icon-scrollToTop.svg'
import './scrollToTop.scss'
const ScrollToTop = () => {
    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
  return (
    <button className={window.scrollY >50 ? 'visibilityYes scrollToTop' : 'visibilityNone'} onClick={scrollToTop}>
        <IconArrow className='icon'/>
    </button>
  )
}

export default ScrollToTop