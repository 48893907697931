import React from 'react';
import i18n from 'i18n';
import { withTranslation } from 'react-i18next';
import { LanguageBox, LanguageDropDown, LanguageElement, LanguageLine } from './LanguageStyled';



const SelectLanguage = ({ t }: { t: any }) => {
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

    return (
        <LanguageBox>
            <LanguageDropDown>
                <LanguageElement onClick={() => changeLanguage('sp')}>{t('language.spanish')}</LanguageElement>
               <LanguageLine/>
                <LanguageElement onClick={() => changeLanguage('en')}>{t('language.english')}</LanguageElement>
            </LanguageDropDown>
        </LanguageBox>
    )
}

export default withTranslation()(SelectLanguage);